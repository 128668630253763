@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
    font-family: 'Switzer';
    src: url('/public/fonts/Switzer_Regular.otf');
    font-style: normal;
  }
  td{
    text-align: center;
  }